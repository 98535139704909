
import { gql } from "apollo-boost";

const WORK_ORDER_ATTRIBUTES = gql`
  fragment workOrderAttributes on WorkOrder {
    _id
    movement_type_id
    wo_no
    nature_of_work
    date
    time
    handler
    carrier
    storage_charges
    handling_charge
    created_by
    forwarding_agent {
      _id 
      name
      company
    }
    forwarding_shipping_agent {
      _id 
      name
      company
    }
    billable_agent{
      _id 
      name
      company 
    }
    shipping_agent {
      _id 
      name
      company
    }
    pm {
      _id
      name
    }
    haulier {
      _id
      name
    }
    equipment_type {
      _id
      name
    }
    vessel_schedule{
      _id
      scn
      scn_vessel_name
      vessel_name
      vessel_id
      voyage_no
      outbound_voyage_no
      eta
      etd
      s_a
      f_a
      remark
    }
    waitingLists{
      _id
      carrier
      container_id
      gate_pass
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      custom_declaration_no
      type
      remark
    }
  }
`



export const GET_WORK_ORDER = gql`
  query ($_id: String) {
    workOrder(_id:$_id){
      ...workOrderAttributes
    }
  }${WORK_ORDER_ATTRIBUTES}
`;

export const ADD_WORK_ORDER = gql`
  mutation addWorkOrder(
    $manual_wo: Boolean
    $carrier: String
    $waitingLists: [WorkOrderWaittingListInput]
    $date: String
    $forwarding_agent:String
    $forwarding_shipping_agent:String #if MPM get date from port user that is MPM 
    $handler: String
    $haulier: EquipmentInput
    $movement_type_id: String
    $pm: EquipmentInput
    $scn_shipping_agent: String
    $shipping_agent: String
    $vessel_schedule_id:String
    $billable_agent: String
    $equipment_type: [String]
  ){
    addWorkOrder(input:{
      manual_wo: $manual_wo
      carrier: $carrier
      waitingLists: $waitingLists
      date: $date
      forwarding_agent: $forwarding_agent
      forwarding_shipping_agent: $forwarding_shipping_agent
      handler: $handler
      haulier: $haulier
      movement_type_id: $movement_type_id
      pm:$pm
      scn_shipping_agent: $scn_shipping_agent
      shipping_agent: $shipping_agent
      vessel_schedule_id: $vessel_schedule_id
      equipment_type: $equipment_type
      billable_agent: $billable_agent
    }
    ){
      ...workOrderAttributes
    }
  }${WORK_ORDER_ATTRIBUTES}
`;

// export const UPDATE_WORK_ORDER = gql`
//   mutation updateWorkOrder(
//     $_id:ID
//     $wo_no: String
//     $nature_of_work: String
//     $time: String
//     $date: String
//     $handler: String
//     $carrier: String
//     $storage_charges: String
//     $handling_charge: String
//     $waitingLists: [WorkOrderWaittingListInput]
//   ){
//     updateWorkOrder(input:{
//       _id: $_id
//       wo_no: $wo_no
//       nature_of_work: $nature_of_work
//       time: $time
//       date :$date
//       handler: $handler
//       carrier: $carrier
//       storage_charges: $storage_charges
//       handling_charge: $handling_charge
//       waitingLists: $waitingLists
//     }
//     ){
//       ...workOrderAttributes
//     }
//   }${WORK_ORDER_ATTRIBUTES}
// `;
export const GET_WORK_ORDERS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    workOrders(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...workOrderAttributes
      }
    }
  }${WORK_ORDER_ATTRIBUTES}
`;
export const REMOVE_CONTAINER_FROM_WORK_ORDER = gql`
  mutation removeContainerFromWorkOrder($_id:ID,$container_id: String){
    removeContainerFromWorkOrder(_id: $_id, container_id: $container_id){
      _id
    }
  }
`;

export const DELETE_WORK_ORDER = gql`
  mutation deleteWorkOrder($_id: ID) {
    deleteWorkOrder(_id: $_id){
      _id
    }
  }
`;

