
import { gql } from "apollo-boost";

const VESSEL_SCHEDULE_ATTRIBUTES = gql`
  fragment vesselScheduleAttributes on VesselSchedule {
    _id
    scn
    scn_vessel_name
    vessel_id
    voyage_no
    outbound_voyage_no
    eta
    etd
    s_a
    f_a
    remark
    vessel{
      _id
      name
    }
    sa_details{
      name
    }
    cargoes{
      _id
      bl
      ie_type
      do
      shipper
      consignee
      mt
      m3
      tariff_code
      notify_party
      quantity
      uom
      description
      markings
      gp_generated
      custom_declaration_no
      storage_mode
    }
    containers{
      _id
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      current_location
      stack_location
      custom_declaration_no
      type
      remark
    }
    import_containers{
      _id
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      custom_declaration_no
      type
      remark
    }
    export_containers{
      _id
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      custom_declaration_no
      type
      remark
    }
  }
`
export const GET_VESSELS_SCHEDULE = gql`
  query ($_id: String, $ie_type: String) {
    vesselSchedule(_id:$_id, ie_type:$ie_type){
      ...vesselScheduleAttributes
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const GET_VESSELS_SCHEDULES = gql`
  query ($keyword: String $limit: Int, $page: Int, $sortBy: String, $sortOrder:Int) {
    vesselSchedules(keyword: $keyword,limit:$limit, page:$page, sortBy:$sortBy, sortOrder:$sortOrder){
      totalResult
      result{
        ...vesselScheduleAttributes
      }
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const GET_VESSELS_SCHEDULES_CARGOES = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    vesselSchedulesWithCargoes(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...vesselScheduleAttributes
      }
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const GET_VESSELS_SCHEDULES_CONTAINERS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    vesselSchedulesWithContainers(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...vesselScheduleAttributes
      }
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const DELETE_VESSEL_SCHEDULE = gql`
  mutation deleteVesselSchedule($_id: ID) {
    deleteVesselSchedule(_id: $_id){
      _id
    }
  }
`;

export const UPDATE_VESSEL_SCHEDULE = gql`
  mutation updateVesselSchedule(
    $_id:ID
    $scn: String,
    $vessel_id: ID,
    $voyage_no: String,
    $outbound_voyage_no: String,
    $eta: String,
    $etd: String,
    $s_a: String,
    $f_a: String,
    $remark: String,
  ){
    updateVesselSchedule(input:{
      _id: $_id
      scn: $scn,
      vessel_id: $vessel_id,
      voyage_no: $voyage_no,
      outbound_voyage_no: $outbound_voyage_no,
      eta: $eta,
      etd: $etd,
      s_a: $s_a,
      f_a: $f_a
      remark: $remark,
    }
    ){
      ...vesselScheduleAttributes
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const ADD_VESSEL_SCHEDULE = gql`
  mutation addVesselSchedule(
    $scn: String,
    $vessel_id: ID,
    $voyage_no: String,
    $outbound_voyage_no: String,
    $eta: String,
    $etd: String,
    $s_a: String,
    $f_a: String,
    $remark: String,
  ){
    addVesselSchedule(input:{
      scn: $scn,
      vessel_id: $vessel_id,
      voyage_no: $voyage_no,
      outbound_voyage_no: $outbound_voyage_no,
      eta: $eta,
      etd: $etd,
      s_a: $s_a,
      f_a: $f_a
      remark: $remark,
    }
    ){
      ...vesselScheduleAttributes
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const UPDATE_CONTAINER_VS = gql`
  mutation updateVSContainer(
    $ie_type: String
    $vessel_schedule_id:ID
    $container_id: String,
    $cargo_id: String,
  ){
    updateVSContainer(ie_type:$ie_type, vessel_schedule_id: $vessel_schedule_id, container_id: $container_id,cargo_id: $cargo_id){
      ...vesselScheduleAttributes
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;

export const REMOVE_CONTAINER_VS = gql`
  mutation removeVSContainer(
    $ie_type: String
    $vessel_schedule_id:ID
    $container_id: String,
    $cargo_id: String,
  ){
    removeVSContainer(ie_type:$ie_type, vessel_schedule_id: $vessel_schedule_id, container_id: $container_id,cargo_id: $cargo_id){
      ...vesselScheduleAttributes
    }
  }${VESSEL_SCHEDULE_ATTRIBUTES}
`;