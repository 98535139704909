
import { gql } from "apollo-boost";

const CARGO_ITEM_ATTRIBUTES = gql`
  fragment cargoItemAttributes on CargoItem {
    _id
    cargo_id
    code
    qty
    unit
    description
    created_date
    updated_date
  }
`
  
export const GET_CARGO_ITEMS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    cargoItems(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...cargoItemAttributes
      }
    }
  }${CARGO_ITEM_ATTRIBUTES}
`;

export const DELETE_CARGO_ITEM = gql`
  mutation deleteCargoItem($_id: ID) {
    deleteCargoItem(_id: $_id){
      _id
    }
  }
`;
export const UPDATE_CARGO_ITEM = gql`
  mutation updateCargoItem(
      $_id: ID,
      $cargo_id: String
      $code: String
      $qty: String
      $unit: String
      $description: String
  ){
    updateCargoItem(input:{
      _id: $_id
      cargo_id: $cargo_id
      code: $code
      qty: $qty
      unit: $unit
      description: $description
    }
    ){
      ...cargoItemAttributes
    }
  }${CARGO_ITEM_ATTRIBUTES}
`;
export const ADD_CARGO_ITEM = gql`
  mutation addCargoItem(
    $cargo_id: String
    $code: String
    $qty: String
    $unit: String
    $description: String
  ){
    addCargoItem(input:{
      cargo_id: $cargo_id
      code: $code
      qty: $qty
      unit: $unit
      description: $description
    }
    ){
      ...cargoItemAttributes
    }
  }${CARGO_ITEM_ATTRIBUTES}
`;