import React, { useState, useEffect } from 'react'
import { ConfigContexts } from './index'
import { useQuery } from '@apollo/react-hooks';
import { GET_CONFIG, GET_PORT_USERS } from './../query'

export default props => {
  const appVersion = process.env.REACT_APP_APP_VERSION || '0.0.0'
  const [portInfo, setPortInfo] = useState({ _id: null, name: null })
  const [isLoading, setIsLoading] = useState(true)
  const [companyInfo, setCompanyInfo] = useState({})
  const [movementTypes, setMovementTypes] = useState([])
  const [containerTypes, setContainerTypes] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [containerStatus, setContainerStatus] = useState([])
  const [portUser, setPortUser] = useState([])
  const [roles, setRoles] = useState([])
  const [showError, setError] = useState({
    error: false,
    message: ''
  })

  const gpTypes = [
    {
      code: 'mp1',
      name: 'Gate Out (MP1)'
    },
    {
      code: 'mp2',
      name: 'Gate Out (MP2)'
    },
    {
      code: 'mp3',
      name: 'Gate In (MP1)'
    },
    {
      code: 'mp4',
      name: 'Gate In (MP2)'
    }
  ]

  const { loading: portUserLoading } = useQuery(GET_PORT_USERS, {
    fetchPolicy: "no-cache",
    variables: { keyword: '', limit: 9999, page: 1 },
    onError(error) {
      setErrMsg({
        error: true,
        message: error.message
      })
    },
    async onCompleted(result) {
      await setPortUser(result.portUsers.result)
    }
  })

  const { loading: configLoading } = useQuery(GET_CONFIG, {
    fetchPolicy: "no-cache",
    variables: { id: "1" },
    onError(err) {
      console.log(err)
      setError({
        error: true,
        message: err.message
      })
    },
    async onCompleted(result) {
      const {
        _id,
        port_name: name,
        movement_type,
        container_type,
        container_status,
        company,
        role
      } = result.config
      await setPortInfo({ _id, name })
      await setCompanyInfo(company)
      await setMovementTypes(movement_type.map((type) => {
        return {
          ...type,
          slug: type.name.toLowerCase().replace(/ /g, '-').replace('(', '').replace(')', '')
        }
      }))
      await setContainerTypes(container_type)
      await setContainerStatus(container_status)
      await setRoles(role)
    }
  });

  useEffect(() => {
    if(!portUserLoading && !configLoading) {
      setIsLoading(false)
    }
  }, [portUser, portUserLoading, configLoading])

  //DO NOT DO THIS, IT WILL PREVENT USER TO LOGIN AFTER THEY LOGOUT
  // if ( showError.error ) {
  //   return <p>{ showError.message }</p>
  // }

  if (isLoading) return <p>Loading...</p>

  return (
    <ConfigContexts.Provider
      value={{
        appVersion,
        isLoading,
        movementTypes,
        portInfo,
        containerTypes,
        containerStatus,
        showError,
        errMsg,
        portUser,
        gpTypes,
        companyInfo,
        setPortUser,
        roles
      }}
    >
      {props.children}
    </ConfigContexts.Provider>
  )
}