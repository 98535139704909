
import { gql } from "apollo-boost";

const WAITING_LIST_ATTRIBUTES = gql`
  fragment waitingListAttributes on WaitingList {
    _id
    movement_type_id
    container_movement_id
    carrier
    container_id
    created_date
    updated_date
    containerDetails{
      gate_pass
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      custom_declaration_no
      type
      remark
    }
  }
`

export const GET_WAITING_LISTS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    waitingLists(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...waitingListAttributes
      }
    }
  }${WAITING_LIST_ATTRIBUTES}
`;

export const DELETE_WAITING_LIST = gql`
  mutation deleteWaitingList($_id: ID) {
    deleteWaitingList(_id: $_id){
      _id
    }
  }
`;

export const ADD_WAITING_LIST = gql`
  mutation addWaitingList(
    $container_id: ID
  ){
    addWaitingList(input:{
      container_id: $container_id
    }
    ){
      _id
      container_id
      created_date
      updated_date
    }
  }${WAITING_LIST_ATTRIBUTES}
`;