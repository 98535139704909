// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import UserContexts from './contexts/users'
import omitDeep from 'omit-deep'

const client = new ApolloClient({
    // uri: "http://localhost:8080/graphql", // Localhost testing server 
    // uri: "http://localhost:9090/graphql", // Localhost production 
    uri: "https://server-tos.ooomax.com/graphql", // Production server
    // uri: "http://192.168.1.9:9090/graphql", // Localhost production 
    request: operation => {
      const token = localStorage.getItem('token')
      if (operation.variables) {
        operation.variables = omitDeep(operation.variables,'__typename')
      }
      operation.setContext({
        headers: {
          authorization: token ? token : ''
        }
      });
    }
  });
  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      //errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-and-network'
    }
  };
const MainApp = () => (
  <ApolloProvider client={client}>
    <UserContexts client={client}>
      <App />
    </UserContexts>
  </ApolloProvider>
)

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
