import React, { useState } from 'react'
import { UserContexts } from './index'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CURRENT_USER, SIGN_IN } from '@query'
import _ from "lodash"

export default props => {
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ userData, setUserData ] = useState(false)
  const [ errMsg, setErrMsg ] = useState('')
  
  const { loading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
    onCompleted(userData) {
      if(!_.isNil(userData)){
        setUserData(userData.currentUser)
        setIsLoggedIn(true)
      } 
    }
  });

  const [ singIn, {loading: isSignInLoading} ] = useMutation(SIGN_IN, {
    onError(err){
      const errorMsg = err.message.replace('GraphQL error:', 'Error: ');
      setErrMsg(errorMsg)
    },
    onCompleted({ login }) {
      setUserData({...login, token:null})
      localStorage.setItem('token', login.token);
      setIsLoggedIn(true)
      // client.writeData({ data: { isLoggedIn: true } });
    }
  });

  const logout = () => {
    setUserData(null)
    localStorage.setItem('token', '')
    setIsLoggedIn(false)
  }

  return(
    <UserContexts.Provider 
      value={{
        loading,
        isLoggedIn,
        setIsLoggedIn,
        singIn,
        userData,
        setUserData,
        logout,
        isSignInLoading,
        errMsg,
      }}
    >
      { props.children }
    </UserContexts.Provider>
  )
}