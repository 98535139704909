
import { gql } from "apollo-boost";

const GATE_PASS_CARGO_ATTRIBUTES = gql`
  fragment gatePassCargoAttributes on GatePassCargo {
    _id
    gp_no
    type
    scn
    hauler
    hauler_name
    ie_type
    lorry
    destination
    remark
    printed_date_time
    forwarding_agent
    forwarding_agent_name
    vessel_schedule_id
    vessel_name
    voyage_no
    outbound_voyage_no
    position
    discharge_at
    storage_expiry_date
    eta
    etd
    custom_declaration_no
    cargo_list{
      _id
      bl
      do
      shipper
      consignee
      custom_declaration_no
      mt
      m3
      tariff_code
      notify_party
      quantity
      uom
      description
      markings
      gp_generated
    }
    created_by
    created_date
    updated_date
  }
`
export const GET_GATE_PASS_CARGO = gql`
  query ($_id: String) {
    gatePassCargo(_id:$_id){
      ...gatePassCargoAttributes
    }
  }${GATE_PASS_CARGO_ATTRIBUTES}
`;

export const GET_GATE_PASS_CARGOS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    gatePassCargoes(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...gatePassCargoAttributes
      }
    }
  }${GATE_PASS_CARGO_ATTRIBUTES}
`;

export const DELETE_GATE_PASS_CARGO = gql`
  mutation deleteGatePassCargo($_id: ID) {
    deleteGatePassCargo(_id: $_id){
      _id
    }
  }
`;

export const ADD_GATE_PASS_CARGO = gql`
  mutation addGatePassCargo(
    $gp_no: String
    $type: String
    $scn: String
    $hauler: String
    $ie_type: String
    $lorry: String
    $destination: String
    $remark: String
    $printed_date_time: String
    $forwarding_agent: String
    $vessel_schedule_id: String
    $vessel_name: String
    $voyage_no: String
    $outbound_voyage_no: String
    $position: String
    $discharge_at: String
    $storage_expiry_date: String
    $eta: String
    $etd: String
    $custom_declaration_no: String
    $cargo_list: [CargoInput]
  ){
    addGatePassCargo(input:{
      gp_no: $gp_no
      type: $type
      scn: $scn
      hauler: $hauler
      ie_type: $ie_type
      lorry: $lorry
      destination: $destination
      remark: $remark
      printed_date_time: $printed_date_time
      forwarding_agent: $forwarding_agent
      vessel_schedule_id: $vessel_schedule_id
      vessel_name: $vessel_name
      voyage_no: $voyage_no
      outbound_voyage_no: $outbound_voyage_no
      position: $position
      discharge_at: $discharge_at
      storage_expiry_date: $storage_expiry_date
      eta: $eta
      etd: $etd
      custom_declaration_no: $custom_declaration_no
      cargo_list: $cargo_list
    }
    ){
      ...gatePassCargoAttributes
    }
  }${GATE_PASS_CARGO_ATTRIBUTES}
`;

export const UPDATE_GATE_PASS_CARGO = gql`
  mutation updateGatePassCargo(
    $_id: ID
    $gp_no: String
    $type: String
    $scn: String
    $hauler: String
    $ie_type: String
    $lorry: String
    $destination: String
    $remark: String
    $printed_date_time: String
    $forwarding_agent: String
    $vessel_schedule_id: String
    $vessel_name: String
    $voyage_no: String
    $outbound_voyage_no: String
    $position: String
    $discharge_at: String
    $storage_expiry_date: String
    $eta: String
    $etd: String
    $custom_declaration_no: String
    $cargo_list: [CargoInput]
  ){
    updateGatePassCargo(input:{
      _id: $_id
      gp_no: $gp_no
      type: $type
      scn: $scn
      hauler: $hauler
      ie_type: $ie_type
      lorry: $lorry
      destination: $destination
      remark: $remark
      printed_date_time: $printed_date_time
      forwarding_agent: $forwarding_agent
      vessel_schedule_id: $vessel_schedule_id
      vessel_name: $vessel_name
      voyage_no: $voyage_no
      outbound_voyage_no: $outbound_voyage_no
      position: $position
      discharge_at: $discharge_at
      storage_expiry_date: $storage_expiry_date
      eta: $eta
      etd: $etd
      custom_declaration_no: $custom_declaration_no
      cargo_list: $cargo_list
    }
    ){
      ...gatePassCargoAttributes
    }
  }${GATE_PASS_CARGO_ATTRIBUTES}
`;