
import { gql } from "apollo-boost";

const EQUIPMENT_ATTRIBUTES = gql`
  fragment EquipmentAttributes on Equipment {
    _id
    name
    is_equipment
    is_haulier
    is_pm
    is_trailer
    remark
    status
    created_date
    updated_date
  }
`
  
export const GET_EQUIPMENTS = gql`
  query (
    $keyword: String
    $is_trailer: Boolean
    $is_equipment: Boolean
    $is_haulier: Boolean
    $is_pm: Boolean
    $limit: Int,
    $page: Int,
    ) {
    equipments(
      keyword: $keyword,
      is_trailer: $is_trailer,
      is_equipment: $is_equipment,
      is_haulier: $is_haulier,
      is_pm: $is_pm,
      limit:$limit, 
      page:$page
      ){
        totalResult
        result{
          ...EquipmentAttributes
        }
    }
  }${EQUIPMENT_ATTRIBUTES}
`;

export const DELETE_EQUIPMENT = gql`
  mutation deleteEquipment($_id: ID) {
    deleteEquipment(_id: $_id){
      _id
    }
  }
`;

export const UPDATE_EQUIPMENT = gql`
  mutation updateEquipment(
      $_id: ID,
      $name: String!
      $is_trailer: Boolean
      $is_equipment: Boolean
      $is_haulier: Boolean
      $is_pm: Boolean
      $remark: String
      $status: Boolean
      $created_date: String
      $updated_date: String
  ){
    updateEquipment(input:{
      _id: $_id
      name: $name
      is_trailer: $is_trailer
      is_equipment: $is_equipment
      is_haulier: $is_haulier
      is_pm: $is_pm
      remark: $remark
      status: $status
      created_date: $created_date
      updated_date: $updated_date
    }
    ){
      ...EquipmentAttributes
    }
  }${EQUIPMENT_ATTRIBUTES}
`;

export const ADD_EQUIPMENT = gql`
  mutation addEquipment(
    $name: String!
    $is_trailer: Boolean
    $is_equipment: Boolean
    $is_haulier: Boolean
    $is_pm: Boolean
    $remark: String
    $status: Boolean
    $created_date: String
    $updated_date: String
  ){
    addEquipment(input:{
      name: $name
      is_trailer: $is_trailer
      is_equipment: $is_equipment
      is_haulier: $is_haulier
      is_pm: $is_pm
      remark: $remark
      status: $status
      created_date: $created_date
      updated_date: $updated_date
    }
    ){
      ...EquipmentAttributes
    }
  }${EQUIPMENT_ATTRIBUTES}
`;