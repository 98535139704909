
import { gql } from "apollo-boost";

const CARGO_ATTRIBUTES = gql`
  fragment cargoAttributes on Cargo {
    _id
    vessel_name
    vessel_id
    ie_type
    eta
    etd
    bl
    do
    shipper
    consignee
    mt
    m3
    tariff_code
    notify_party
    quantity
    uom
    vessel_schedule_id
    description
    markings
    custom_declaration_no
    gp_generated
    storage_mode
    current_location
    stage
    containers{
      _id
      gate_pass
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      current_location
      stage
      custom_declaration_no
      type
      remark
    }
  }
`
export const GET_CARGO = gql`
query ($_id: String) {
  cargo(_id: $_id){
    ...cargoAttributes
  }
}${CARGO_ATTRIBUTES}
`;

export const GET_CARGOES = gql`
  query (
    $keyword: String $vesselScheduleId: String, $limit: Int, 
    $page: Int, $sortBy: String, $sortOrder:Int, $ie_type:String
    ) {
      cargoes(
        keyword: $keyword, vesselScheduleId:$vesselScheduleId, limit:$limit, 
        page:$page, sortBy:$sortBy, sortOrder:$sortOrder, ie_type:$ie_type){
          totalResult
          result{
            ...cargoAttributes
          }
      }
    }${CARGO_ATTRIBUTES}
`;

export const DELETE_CARGO = gql`
  mutation deleteCargo($_id: ID) {
    deleteCargo(_id: $_id){
      _id
    }
  }
`;
export const UPDATE_CARGO = gql`
  mutation updateCargo(
      $_id: ID,
      $vessel_name: String
      $vessel_id: String
      $ie_type: String
      $eta: String
      $etd: String
      $bl: String
      $do: String
      $shipper: String
      $consignee: String
      $mt: String
      $m3: String
      $tariff_code: String
      $notify_party: String
      $quantity: String
      $uom: String
      $vessel_schedule_id: String
      $description: String
      $markings: String
      $custom_declaration_no: String
      $gp_generated: Boolean
      $storage_mode: String
  ){
    updateCargo(input:{
      _id:$_id
      vessel_name:$vessel_name
      vessel_id: $vessel_id
      ie_type: $ie_type
      eta: $eta
      etd: $etd
      bl: $bl
      do: $do
      shipper: $shipper
      consignee: $consignee
      mt: $mt
      m3: $m3
      tariff_code: $tariff_code
      notify_party: $notify_party
      quantity: $quantity
      uom: $uom
      vessel_schedule_id: $vessel_schedule_id
      description: $description
      markings: $markings
      custom_declaration_no: $custom_declaration_no
      gp_generated: $gp_generated
      storage_mode: $storage_mode
    }
    ){
      ...cargoAttributes
    }
  }${CARGO_ATTRIBUTES}
`;
export const ADD_CARGO = gql`
  mutation addCargo(
    $vessel_name: String
    $vessel_id: String
    $ie_type: String
    $eta: String
    $etd: String
    $bl: String
    $do: String
    $shipper: String
    $consignee: String
    $mt: String
    $m3: String
    $tariff_code: String
    $notify_party: String
    $quantity: String
    $uom: String
    $vessel_schedule_id: String
    $description: String
    $markings: String
    $custom_declaration_no: String
    $gp_generated: Boolean
    $storage_mode: String
  ){
    addCargo(input:{
      vessel_name:$vessel_name
      vessel_id: $vessel_id
      ie_type: $ie_type
      eta: $eta
      etd: $etd
      bl: $bl
      do: $do
      shipper: $shipper
      consignee: $consignee
      mt: $mt
      m3: $m3
      tariff_code: $tariff_code
      notify_party: $notify_party
      quantity: $quantity
      uom: $uom
      vessel_schedule_id: $vessel_schedule_id
      description: $description
      markings: $markings
      custom_declaration_no: $custom_declaration_no
      gp_generated: $gp_generated
      storage_mode: $storage_mode
    }
    ){
      ...cargoAttributes
    }
  }${CARGO_ATTRIBUTES}
`;