
import { gql } from "apollo-boost";

const CONFIG_ATTRIBUTES = gql`
  fragment configAttributes on Config {
    _id
    id
    port_name
    scn_year
    scn_number
    movement_type {
      _id
      sorting
      name
      ie_type
      check_point
      check_point_name
      gate_pass_required
      gate_pass_needed
    }
    container_type { 
      code
      name
      limit
    }
    container_status {
      code
      name
    }
    container_stage {
      stage_id
      status
      location
      description
    }
    company{ 
      name
      reg_no
      address
      tel
      fax
      website
      email
     }
    role{
      admin {name , permission }
      frontdesk {name , permission }
      frontdesk_supervisor { name , permission }
    }
  }
`
export const GET_CONFIG = gql`
  query ($id: String) {
    config(id: $id){
      ...configAttributes
    }
  }${CONFIG_ATTRIBUTES}
`;
