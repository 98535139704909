
import { gql } from "apollo-boost";

const VESSEL_ATTRIBUTES = gql`
  fragment vesselAttributes on Vessel {
      _id
      vessel_id
      name
      imo
      call_sign
      year_of_built
      grt
      nrt
      deadweight_tonnage
      loa
      draft
      class_of_vessel
      type_of_vessel
      type_of_cargo
      registration_port
      registration_country
      nationality
      conference
      line
      consortium
      vessel_owner_name
      agency_name
      address
      tel
      fax
      email
      remark
      created_by
      created_date
      updated_date
  }
`

export const GET_VESSEL = gql`
  query ($_id: String) {
    vessel(_id: $_id){
      ...vesselAttributes
    }
  }${VESSEL_ATTRIBUTES}
`;
export const GET_VESSELS = gql`
  query ($keyword: String $limit: Int, $page: Int, $sortBy: String, $sortOrder:Int) {
    vessels(keyword: $keyword,limit:$limit, page:$page, sortBy:$sortBy, sortOrder:$sortOrder){
      totalResult
      result{
        ...vesselAttributes
      }
    }
  }${VESSEL_ATTRIBUTES}
`;

export const DELETE_VESSEL = gql`
  mutation deleteVessel($_id: ID) {
    deleteVessel(_id: $_id){
      _id
    }
  }
`;

export const UPDATE_VESSEL = gql`
  mutation updateVessel(
    $_id:ID
    $name: String,
    $imo: String,
    $call_sign: String,
    $year_of_built: String,
    $grt: String,
    $nrt: String,
    $deadweight_tonnage: String,
    $loa: String,
    $draft: String,
    $class_of_vessel: String,
    $type_of_vessel: String,
    $type_of_cargo: String,
    $registration_port: String,
    $registration_country: String,
    $nationality: String,
    $conference: String,
    $line: String,
    $consortium: String,
    $vessel_owner_name: String,
    $agency_name: String,
    $address: String,
    $tel: String,
    $fax: String,
    $email: String,
    $remark: String,
  ){
    updateVessel(input:{
      _id: $_id
      name: $name
      imo: $imo
      call_sign: $call_sign,
      year_of_built: $year_of_built,
      grt: $grt,
      nrt: $nrt,
      deadweight_tonnage: $deadweight_tonnage,
      loa: $loa,
      draft: $draft,
      class_of_vessel: $class_of_vessel,
      type_of_vessel: $type_of_vessel,
      type_of_cargo: $type_of_cargo,
      registration_port: $registration_port,
      registration_country: $registration_country,
      nationality: $nationality,
      conference: $conference,
      line: $line,
      consortium: $consortium,
      vessel_owner_name: $vessel_owner_name,
      agency_name: $agency_name,
      address: $address,
      tel: $tel,
      fax: $fax,
      email: $email,
      remark: $remark,
    }
    ){
      ...vesselAttributes
    }
  }${VESSEL_ATTRIBUTES}
`;

export const ADD_VESSEL = gql`
  mutation addVessel(
    $name: String,
    $imo: String,
    $call_sign: String,
    $year_of_built: String,
    $grt: String,
    $nrt: String,
    $deadweight_tonnage: String,
    $loa: String,
    $draft: String,
    $class_of_vessel: String,
    $type_of_vessel: String,
    $type_of_cargo: String,
    $registration_port: String,
    $registration_country: String,
    $nationality: String,
    $conference: String,
    $line: String,
    $consortium: String,
    $vessel_owner_name: String,
    $agency_name: String,
    $address: String,
    $tel: String,
    $fax: String,
    $email: String,
    $remark: String,
  ){
    addVessel(input:{
      name: $name
      imo: $imo
      call_sign: $call_sign,
      year_of_built: $year_of_built,
      grt: $grt,
      nrt: $nrt,
      deadweight_tonnage: $deadweight_tonnage,
      loa: $loa,
      draft: $draft,
      class_of_vessel: $class_of_vessel,
      type_of_vessel: $type_of_vessel,
      type_of_cargo: $type_of_cargo,
      registration_port: $registration_port,
      registration_country: $registration_country,
      nationality: $nationality,
      conference: $conference,
      line: $line,
      consortium: $consortium,
      vessel_owner_name: $vessel_owner_name,
      agency_name: $agency_name,
      address: $address,
      tel: $tel,
      fax: $fax,
      email: $email,
      remark: $remark,
    }
    ){
      ...vesselAttributes
    }
  }${VESSEL_ATTRIBUTES}
`;