
import { gql } from "apollo-boost";

const CONTAINER_MOVEMENT_ATTRIBUTES = gql`
  fragment containerMovementAttributes on ContainerMovement {
    _id
    next_action
    current_action
    movement_type_id
    container_id
    shipping_agent {
      _id 
      name
      company
    }
    operator_in_charge {
      _id 
      name
      company
    }
    actions{
      action
      status
      stage
      confirmed_at
      equipment
      remarks
    }
    created_by
    created_date
    updated_date
  }
`

export const GET_CONTAINER_MOVEMENTS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    containerMovements(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...containerMovementAttributes
      }
    }
  }${CONTAINER_MOVEMENT_ATTRIBUTES}
`;

export const DELETE_CONTAINER_MOVEMENT = gql`
  mutation deleteContainerMovement($_id: ID) {
    deleteContainerMovement(_id: $_id){
      _id
    }
  }
`;

export const UPDATE_CONTAINER_MOVEMENT = gql`
  mutation updateContainerMovement(
    $_id:ID
    $next_action: String
    $shipping_agent: String
    $operator_in_charge: String
    $current_action: Int
    $movement_type_id: String
    $container_id: String
    $actions: [actionInput]
  ){
    updateContainerMovement(input:{
      _id: $_id
      next_action: $next_action
      shipping_agent: $shipping_agent
      operator_in_charge: $operator_in_charge
      current_action: $current_action
      movement_type_id: $movement_type_id
      container_id: $container_id
      actions: $actions
    }
    ){
      ...containerMovementAttributes
    }
  }${CONTAINER_MOVEMENT_ATTRIBUTES}
`;

export const ADD_CONTAINER_MOVEMENT = gql`
  mutation addContainerMovement(
    $next_action: String
    $shipping_agent: String
    $operator_in_charge: String
    $current_action: Int
    $movement_type_id: String
    $container_id: String
    $actions: [actionInput]
  ){
    addContainerMovement(input:{
      next_action: $next_action
      shipping_agent: $shipping_agent
      operator_in_charge: $operator_in_charge
      current_action: $current_action
      movement_type_id: $movement_type_id
      container_id: $container_id
      actions: $actions
    }
    ){
      ...containerMovementAttributes
    }
  }${CONTAINER_MOVEMENT_ATTRIBUTES}
`;