
import { gql } from "apollo-boost";

const PORT_USER_ATTRIBUTES = gql`
  fragment portUserAttributes on PortUser {
    _id
    name
    company
    is_shipping_agent
    is_forward_agent
    is_carrier
    is_lifter
    is_consignee
    is_notify_party
    is_shipper
    is_haulage
    is_operator
    is_handler
    remark
  }
`
  
export const GET_PORT_USERS = gql`
  query (
    $keyword: String 
    $limit: Int,
    $page: Int,
    $is_shipping_agent: Boolean
    $is_forward_agent: Boolean
    $is_carrier: Boolean
    $is_lifter: Boolean
    $is_consignee: Boolean
    $is_notify_party: Boolean
    $is_shipper: Boolean
    $is_haulage: Boolean
    $is_operator: Boolean
    $is_handler: Boolean
    ) {
    portUsers(
      keyword: $keyword,
      limit:$limit, 
      page:$page
      is_shipping_agent: $is_shipping_agent
      is_forward_agent: $is_forward_agent
      is_carrier: $is_carrier
      is_lifter: $is_lifter
      is_consignee: $is_consignee
      is_notify_party: $is_notify_party
      is_shipper: $is_shipper
      is_haulage: $is_haulage
      is_operator: $is_operator
      is_handler: $is_handler
      ){
        totalResult
        result{
          ...portUserAttributes
        }
    }
  }${PORT_USER_ATTRIBUTES}
`;

export const DELETE_PORT_USER = gql`
  mutation deletePortUser($_id: ID) {
    deletePortUser(_id: $_id){
      _id
    }
  }
`;

export const UPDATE_PORT_USER = gql`
  mutation updatePortUser(
      $_id: ID,
      $name: String!
      $company: String!
      $is_shipping_agent: Boolean
      $is_forward_agent: Boolean
      $is_carrier: Boolean
      $is_lifter: Boolean
      $is_consignee: Boolean
      $is_notify_party: Boolean
      $is_shipper: Boolean
      $is_haulage: Boolean
      $is_operator: Boolean
      $is_handler: Boolean
      $remark: String
  ){
    updatePortUser(input:{
      _id: $_id
      name :$name
      company: $company
      is_shipping_agent: $is_shipping_agent
      is_forward_agent: $is_forward_agent
      is_carrier: $is_carrier
      is_lifter: $is_lifter
      is_consignee: $is_consignee
      is_notify_party: $is_notify_party
      is_shipper: $is_shipper
      is_haulage: $is_haulage
      is_operator: $is_operator
      is_handler: $is_handler
      remark: $remark
    }
    ){
      ...portUserAttributes
    }
  }${PORT_USER_ATTRIBUTES}
`;

export const ADD_PORT_USER = gql`
  mutation addPortUser(
    $name: String!
    $company: String!
    $is_shipping_agent: Boolean
    $is_forward_agent: Boolean
    $is_carrier: Boolean
    $is_lifter: Boolean
    $is_consignee: Boolean
    $is_notify_party: Boolean
    $is_shipper: Boolean
    $is_haulage: Boolean
    $is_operator: Boolean
    $is_handler: Boolean
    $remark: String
  ){
    addPortUser(input:{
      name :$name
      company: $company
      is_shipping_agent: $is_shipping_agent
      is_forward_agent: $is_forward_agent
      is_carrier: $is_carrier
      is_lifter: $is_lifter
      is_consignee: $is_consignee
      is_notify_party: $is_notify_party
      is_shipper: $is_shipper
      is_haulage: $is_haulage
      is_operator: $is_operator
      is_handler: $is_handler
      remark: $remark
    }
    ){
      ...portUserAttributes
    }
  }${PORT_USER_ATTRIBUTES}
`;