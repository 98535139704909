
import { gql } from "apollo-boost";

const CONTAINER_ATTRIBUTES = gql`
  fragment containerAttributes on Container {
    _id
    gate_pass
    container_no
    size
    el
    vessel_schedule_id
    cargo_id
    status
    container_operator
    current_location
    stage
    stack_location
    custom_declaration_no
    type
    remark
  }
`
export const GET_CONTAINER = gql`
  query ($_id: String) {
    container(_id:$_id){
      ...containerAttributes
    }
  }${CONTAINER_ATTRIBUTES}
`;

export const GET_CONTAINERS = gql`
  query (
    $keyword: String, 
    $vesselScheduleId: String, 
    $cargoId:String 
    $limit: Int, 
    $page: Int, 
    $sortBy: String, 
    $sortOrder:Int,
    $status: String, 
    ) {
      containers(
        keyword:$keyword, 
        vesselScheduleId:$vesselScheduleId, 
        cargoId:$cargoId 
        limit:$limit, 
        page:$page, 
        sortBy:$sortBy, 
        status: $status, 
        sortOrder:$sortOrder){
          totalResult
          result{
            ...containerAttributes
          }
    }
  }${CONTAINER_ATTRIBUTES}
`;

export const DELETE_CONTAINER = gql`
  mutation deleteContainer($_id: ID) {
    deleteContainer(_id: $_id){
      _id
    }
  }
`;

export const UPDATE_CONTAINER = gql`
  mutation updateContainer(
      $_id: ID,
      $gate_pass: String,
      $container_no: String,
      $size: String,
      $el: String,
      $vessel_schedule_id: String
      $cargo_id: String
      $status: String
      $container_operator: String
      $current_location: String
      $stack_location: String
      $custom_declaration_no: String
      $type: String
      $remark: String
  ){
    updateContainer(input:{
      _id: $_id,
      gate_pass: $gate_pass
      container_no: $container_no
      size: $size
      el: $el
      vessel_schedule_id: $vessel_schedule_id
      cargo_id: $cargo_id
      status: $status
      container_operator: $container_operator
      current_location: $current_location
      stack_location: $stack_location
      custom_declaration_no: $custom_declaration_no
      type: $type
      remark: $remark
    }
    ){
      ...containerAttributes
    }
  }${CONTAINER_ATTRIBUTES}
`;

export const ADD_CONTAINER = gql`
  mutation addContainer(
    $gate_pass: String,
    $container_no: String,
    $size: String,
    $el: String,
    $vessel_schedule_id: String
    $cargo_id: String
    $status: String
    $container_operator: String
    $current_location: String
    $stack_location: String
    $custom_declaration_no: String
    $type: String
    $remark: String
  ){
    addContainer(input:{
      gate_pass: $gate_pass
      container_no: $container_no
      size: $size
      el: $el
      vessel_schedule_id: $vessel_schedule_id
      cargo_id: $cargo_id
      status: $status
      container_operator: $container_operator
      current_location: $current_location
      stack_location: $stack_location
      custom_declaration_no: $custom_declaration_no
      type: $type
      remark: $remark
    }
    ){
      ...containerAttributes
    }
  }${CONTAINER_ATTRIBUTES}
`;