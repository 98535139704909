
import { gql } from "apollo-boost";

const CM_HISTORY_ATTRIBUTES = gql`
  fragment CMHistoryAttributes on CMHistory {
    _id
    vs_id
    scn
    inbound_voyage_no
    outbound_voyage_no
    vessel_name
    scn_vessel_name
    container_id
    container_no
    container_size
    container_type
    container_status
    container_operator {
      _id 
      name
      company
    }
    shipping_agent {
      _id 
      name
      company
    }
    equipment_type { 
      _id
      name
    }
    movement_type_name
    pm_no
    remark
    comfirmed_at
  }
`

export const GET_CM_HISTORY = gql`
   query ($input: CMHistoryInput) {
    CMHistorys(input: $input){
      totalResult
      result{
        ...CMHistoryAttributes
      }
    }
  }${CM_HISTORY_ATTRIBUTES}
`;

