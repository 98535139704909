
import { gql } from "apollo-boost";

const CARGO_WORK_ORDER_ATTRIBUTES = gql`
  fragment cargoWorkOrderAttributes on CargoWorkOrder {
    _id
    wo_no
    movement_type_id
    date
    handler
    carrier
    forwarding_agent {
      _id 
      name
      company
    }
    billable_agent{
      _id 
      name
      company 
    }
    equipment_type {
      _id
      name
    }
    haulier {
      _id
      name
    }
    shipping_agent {
      _id 
      name
      company
    }
    vessel_schedule{
      _id
      scn
      scn_vessel_name
      vessel_name
      vessel_id
      voyage_no
      outbound_voyage_no
      eta
      etd
      s_a
      f_a
      remark
    }
    cargoes {
      _id
      vessel_name
      vessel_id
      ie_type
      eta
      etd
      bl
      do
      shipper
      consignee
      mt
      m3
      tariff_code
      notify_party
      quantity
      uom
      vessel_schedule_id
      description
      markings
      custom_declaration_no
      gp_generated
      containers{
        _id
        gate_pass
        container_no
        size
        el
        vessel_schedule_id
        cargo_id
        status
        container_operator
        custom_declaration_no
        type
        remark
      }
    }
    created_by
    created_date
    updated_date
  }
`
export const GET_CARGO_WORK_ORDER = gql`
  query ($_id: String) {
    cargoWorkOrder(_id:$_id){
      ...cargoWorkOrderAttributes
    }
  }${CARGO_WORK_ORDER_ATTRIBUTES}
`;

export const GET_CARGO_WORK_ORDERS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    cargoWorkOrders(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...cargoWorkOrderAttributes
      }
    }
  }${CARGO_WORK_ORDER_ATTRIBUTES}
`;

export const ADD_CAROG_WORK_ORDER = gql`
  mutation addCargoWorkOrder(
    $movement_type_id: String
    $date: String
    $handler: String
    $carrier: String
    $cargoes: [CargoInput]

    $billable_agent: String
    $equipment_type: [String]
    $haulier: EquipmentInput
    $shipping_agent: String
    $forwarding_agent:String
    $vessel_schedule_id:String
  ){
    addCargoWorkOrder(input:{
      movement_type_id: $movement_type_id
      date :$date
      handler: $handler
      carrier: $carrier
      cargoes: $cargoes
      billable_agent: $billable_agent
      equipment_type: $equipment_type
      haulier: $haulier
      shipping_agent: $shipping_agent
      forwarding_agent: $forwarding_agent
      vessel_schedule_id: $vessel_schedule_id
    }
    ){
      ...cargoWorkOrderAttributes
    }
  }${CARGO_WORK_ORDER_ATTRIBUTES}
`;

export const REMOVE_CARGO_FROM_WORK_ORDER = gql`
  mutation removeCargoFromWorkOrder($_id:ID,$cargo_id: String){
    removeCargoFromWorkOrder(_id: $_id, cargo_id: $cargo_id){
      _id
    }
  }
`;

export const UPDATE_CARGO_WORK_ORDER = gql`
  mutation updateCargoWorkOrder(
    $_id:ID
    $movement_type_id: String
    $date: String
    $handler: String
    $carrier: String
    $cargoes: [CargoInput]

    $billable_agent: String
    $equipment_type: [String]
    $haulier: EquipmentInput
    $shipping_agent: String
    $forwarding_agent:String
    $vessel_schedule_id:String
  ){
    updateCargoWorkOrder(input:{
      _id: $_id
      movement_type_id: $movement_type_id
      date :$date
      handler: $handler
      carrier: $carrier
      cargoes: $cargoes
      billable_agent: $billable_agent
      equipment_type: $equipment_type
      haulier: $haulier
      shipping_agent: $shipping_agent
      forwarding_agent: $forwarding_agent
      vessel_schedule_id: $vessel_schedule_id
    }
    ){
      ...cargoWorkOrderAttributes
    }
  }${CARGO_WORK_ORDER_ATTRIBUTES}
`;
export const DELETE_CARGO_WORK_ORDER = gql`
  mutation deleteCargoWorkOrder($_id: ID) {
    deleteCargoWorkOrder(_id: $_id){
      _id
    }
  }
`;

