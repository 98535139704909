
import { gql } from "apollo-boost";

const GATE_PASS_CONTAINER_ATTRIBUTES = gql`
  fragment gatePassContainerAttributes on GatePassContainer {
    _id,
    gp_no
    gp_type
    scn
    lorry
    ie_type
    position
    destination
    seal_no
    mt_return_depot
    hauler
    discharge_at
    storage_expiry_date
    remark
    submitted
    custom_declaration_no
    forwarding_agent {
      _id 
      name
      company
    }
    container{ 
      _id
      container_no
      size
      el
      vessel_schedule_id
      cargo_id
      status
      container_operator
      type
      remark
    }
    vs{
      _id
      scn
      vessel_id
      voyage_no
      outbound_voyage_no
      eta
      etd
      s_a
      f_a
      remark
      vessel_name
      cargoes
      name
    }
    cargo{
      _id
      vessel_name
      vessel_id
      eta
      etd
      bl
      do
      shipper
      consignee
      mt
      m3
      tariff_code
      notify_party
      quantity
      uom
      vessel_schedule_id
      description
      markings
      gp_generated
    }
    created_by
    created_date
    updated_date
  }
`
export const GET_GATE_PASS_CONTAINER = gql`
  query ($_id: String) {
    gatePassContainer(_id:$_id){
      ...gatePassContainerAttributes
    }
  }${GATE_PASS_CONTAINER_ATTRIBUTES}
`;

export const GET_GATE_PASS_CONTAINERS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    gatePassContainers(keyword: $keyword,limit:$limit, page:$page){
      totalResult
      result{
        ...gatePassContainerAttributes
      }
    }
  }${GATE_PASS_CONTAINER_ATTRIBUTES}
`;

export const DELETE_GATE_PASS_CONTAINER = gql`
  mutation deleteGatePassContainer($_id: ID) {
    deleteGatePassContainer(_id: $_id){
      _id
    }
  }
`;
export const ADD_GATE_PASS_CONTAINER = gql`
  mutation addGatePassContainer(
    $gatePsssContainer: [GatePassContainerInput]
  ){
    addGatePassContainer(input:{
      gatePsssContainer: $gatePsssContainer
    }
    ){
      totalResult
      result{
        ...gatePassContainerAttributes
      }
    }
  }${GATE_PASS_CONTAINER_ATTRIBUTES}
`;