import React from 'react'

//Main config
export const ConfigContexts = React.createContext();

// vessel
export const VesselContexts = React.createContext();
export const VesselScheduleContexts = React.createContext();
export const VesselScheduleImportContexts = React.createContext();
export const VesselScheduleExporttContexts = React.createContext();

// container
export const ContainersContexts = React.createContext();
export const ContainerDetailsContexts = React.createContext();

// cargo
export const CargoesContexts = React.createContext();
export const CargoDetailsContexts = React.createContext();

// movement history
export const MovementHistoriesContexts = React.createContext();

// work order (container)
export const ContainerWorkOrdersContexts = React.createContext();
export const ContainerWorkOrderDetailsContexts = React.createContext();

// work order (cargo)
export const CargoWorkOrdersContexts = React.createContext();
export const CargoWorkOrderDetailsContexts = React.createContext();

// gate pass (container)
export const GatePassContainerContexts = React.createContext();
export const GatePassContainerDetailsContexts = React.createContext();
export const GatePassContainerFormContexts = React.createContext();

// gate pass (cargo)
export const GatePassCargoContexts = React.createContext();
export const GatePassCargoDetailsContexts = React.createContext();
export const GatePassCargoFormContexts = React.createContext();

// user
export const UserContexts = React.createContext();
export const PortUserLocalContexts = React.createContext();
export const ACContexts = React.createContext();

// Equipment
export const equipmentContexts = React.createContext();

// Reports
export const MovementReportContexts = React.createContext();