
import { gql } from "apollo-boost";

const USER_ATTRIBUTES = gql`
  fragment userAttributes on User {
    _id
    username
    first_name
    last_name
    email
    contact_no
    role
  }
`

export const GET_CURRENT_USER = gql`
    query {
        currentUser{
            _id
            username
            first_name
            last_name
            email
            contact_no
            role
        }
    }
`;
export const GET_USERS = gql`
  query ($keyword: String $limit: Int, $page: Int) {
    users(keyword: $keyword, limit:$limit, page:$page){
      totalResult
      result{
        ...userAttributes
      }
    }
  }${USER_ATTRIBUTES}
`;
export const SIGN_IN = gql`
    mutation SignIn($username: String!, $password: String!){
        login(username:$username,password:$password){
            username
            first_name
            last_name
            token
        }
    }
`;
export const CREATE_USER = gql`
  mutation register(
    $username: String!
    $password: String!
    $first_name: String
    $last_name: String
    $email: String
    $contact_no: String
    $role: String!
  ){
    register(input:{
        username: $username
        password: $password
        first_name: $first_name
        last_name: $last_name
        email: $email
        contact_no: $contact_no
        role: $role
    }){
      ...userAttributes
    }
  }${USER_ATTRIBUTES}
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $_id:ID
    $username: String!
    $password: String!
    $first_name: String
    $last_name: String
    $email: String
    $contact_no: String
    $role: String!
  ){
    updateUser(input:{
        _id: $_id
        username: $username
        password: $password
        first_name: $first_name
        last_name: $last_name
        email: $email
        contact_no: $contact_no
        role: $role
    }
    ){
      ...userAttributes
    }
  }${USER_ATTRIBUTES}
`;
export const DELETE_USER = gql`
  mutation deleteContainer($_id: ID) {
    deleteUser(_id: $_id){
      _id
    }
  }
`;