import { hot } from 'react-hot-loader'
import React, {useContext} from 'react';
import { 
  HashRouter, 
  // BrowserRouter, 
  Route, 
  Switch 
} from 'react-router-dom';
import { UserContexts,
   ConfigContexts
   } from './contexts'
import ConfigConsumer from './contexts/config'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// work order
const PrintContainerWorkOrder = React.lazy(() => import('./views/ContainerWorkOrder/Print'));
const PrintCargoWorkOrder = React.lazy(() => import('./views/CargoWorkOrder/Print'));
// gate pass
const PrintGatePassContainer = React.lazy(() => import('./views/ContainerGatePass/GatePassContainerDetails/print'));
const PrintGatePassCargo = React.lazy(() => import('./views/CargoGatePass/GatePassCargoDetails/print'));
// tally sheet (container)
const PrintContainerJettyImportTallySheet = React.lazy(() => import('./views/TallySheet/Container/JettyImport'));
const PrintContainerYardImportTallySheet = React.lazy(() => import('./views/TallySheet/Container/YardImport'));
const PrintContainerJettyExportTallySheet = React.lazy(() => import('./views/TallySheet/Container/JettyExport'));
const PrintContainerYardExportTallySheet = React.lazy(() => import('./views/TallySheet/Container/YardExport'));
// tally sheet (cargo)
const PrintCargoJettyImportTallySheet = React.lazy(() => import('./views/TallySheet/Cargo/JettyImport'));
const PrintCargoYardImportTallySheet = React.lazy(() => import('./views/TallySheet/Cargo/WarehouseImport'));
const PrintCargoJettyExportTallySheet = React.lazy(() => import('./views/TallySheet/Cargo/JettyExport'));
const PrintCargoYardExportTallySheet = React.lazy(() => import('./views/TallySheet/Cargo/WarehouseExport'));
const Login = React.lazy(() => import('./views/Pages/Login'));

const App = () => {
  const { isLoggedIn } = useContext(UserContexts)
  
    return (
        <HashRouter>
          <React.Suspense fallback={loading()}>
            { isLoggedIn
            ?
            <ConfigConsumer>
            <ConfigContexts.Consumer>
              {Config =>( 
                <>
                  { !Config.isLoading &&
                    <Switch>
                      <Route path="/gate-pass/container/:id/print" exact name="Print" render={props => <PrintGatePassContainer {...props}/>} />
                      <Route path="/gate-pass/cargo/:id/print" exact name="Print" render={props => <PrintGatePassCargo {...props}/>} />
                      <Route path="/work-orders/container/:id/print" exact name="Print" render={props => <PrintContainerWorkOrder {...props}/>} />
                      <Route path="/work-orders/cargo/:id/print" exact name="Print" render={props => <PrintCargoWorkOrder {...props}/>} />
                      <Route path="/vessel-schedules/:id/import/container/jetty/print" exact name="Print" render={props => <PrintContainerJettyImportTallySheet {...props}/>} />
                      <Route path="/vessel-schedules/:id/import/container/yard/print" exact name="Print" render={props => <PrintContainerYardImportTallySheet {...props}/>} />
                      <Route path="/vessel-schedules/:id/export/container/jetty/print" exact name="Print" render={props => <PrintContainerJettyExportTallySheet {...props}/>} />
                      <Route path="/vessel-schedules/:id/export/container/yard/print" exact name="Print" render={props => <PrintContainerYardExportTallySheet {...props}/>} />
                      
                      <Route path="/vessel-schedules/:id/import/cargo/jetty/print" exact name="Print" render={props => <PrintCargoJettyImportTallySheet {...props}/>} />
                      <Route path="/vessel-schedules/:id/import/cargo/warehouse/print" exact name="Print" render={props => <PrintCargoYardImportTallySheet {...props}/>} />
                      <Route path="/vessel-schedules/:id/export/cargo/jetty/print" exact name="Print" render={props => <PrintCargoJettyExportTallySheet {...props}/>} />
                      <Route path="/vessel-schedules/:id/export/cargo/warehouse/print" exact name="Print" render={props => <PrintCargoYardExportTallySheet {...props}/>} />
                      <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                    </Switch>
                  } 
                </> 
              )}
              </ConfigContexts.Consumer>
             </ConfigConsumer>
              :
              <Switch>
                <Route path="/" name="login" render={props => <Login {...props}/>} />
              </Switch>   
            }
          </React.Suspense>
        </HashRouter>
    );
  }

// export default App;
export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App
